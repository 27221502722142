import React from 'react';
import JobTitle from './JobTitle';
import Edit from '../../layout/Edit';
import JobForm from './JobForm';

const JobEdit = props => {
  return (
  <Edit redirect="show" title={<JobTitle />} {...props}>
    <JobForm />
  </Edit>
)};

export default JobEdit;
