import React from "react";
import { Navigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import CommitmentCreate from './CommitmentCreate';
import CommitmentEdit from './CommitmentEdit';
import CommitmentShow from './CommitmentShow';

const RedirectToHomepage = () => <Navigate to="/" replace />;

export default {
  config: {
    list: RedirectToHomepage,
    show: CommitmentShow,
    create: CommitmentCreate,
    edit: CommitmentEdit,
    icon: SettingsIcon,
    options: {
      label: 'Contribution'
    }
  },
  dataModel: {
    types: ['heco:Commitment'],
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Contribution |||| Contributions',
      fields: {
        'pair:label': 'Titre de la contribution',
        'pair:description': 'Description',
        'rdfs:comment': 'Courte description',
        'heco:startDate': 'Date de début',
        'heco:endDate': 'Date de fin',
        'heco:contributedTo': "A contribué au(x) projet(s)",
        'heco:hasTopic': 'A pour thèmes',
        'heco:acquiredCompetence': "Compétence(s) acquise(s)"
      }
    },
    en: {
      name: 'Commitment |||| Commitments',
      fields: {
        'pair:label': 'Title',
        'pair:description': 'Description',
        'rdfs:comment': 'Short description',
        'heco:startDate': 'Start date',
        'heco:endDate': 'End date',
        'heco:contributedTo': 'Contributed to (projects)',
        'heco:hasTopic': 'Has topic',
        'heco:acquiredCompetence': "Acquired competence"
      }
    }
  }
};
