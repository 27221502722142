import React from 'react';
import { TextInput, SimpleForm, SelectInput } from 'react-admin';
import { MarkdownInput } from '@semapps/markdown-components';
import { OrganizationsInput, 
  ThemesInput, 
  DateTimeInput, 
  CompetencesInput, 
  OccupationsInput } from '../../common/input';
// import { ReferenceInput, ImageInput} from '@semapps/input-components';
import ReferenceInput from '../../common/input/ReferenceInput';
import { useGetIdentity } from 'react-admin';

const JobForm = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  if (identityLoading) return <>Loading...</>;

  return (
    <SimpleForm redirect="show">
      <TextInput source="pair:label" fullWidth />
      <TextInput source="rdfs:comment" fullWidth />
      <ReferenceInput reference="Type" source="pair:hasType" filter={{ a: 'pair:JobType' }}>
        <SelectInput optionText="pair:label" />
      </ReferenceInput>
      <OrganizationsInput source="heco:heldAt" />
      <OccupationsInput source="heco:hasOccupation" />
      <CompetencesInput source="heco:acquiredCompetence" identity={identity} />
      <MarkdownInput multiline source="pair:description" fullWidth />
      <DateTimeInput source="heco:startDate" />
      <DateTimeInput source="heco:endDate" />
      <ThemesInput source="heco:hasTopic" />
    </SimpleForm>
  );
};

export default JobForm;
