import React from "react";
// import { Redirect } from 'react-router';
import { Navigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import CompetenceCreate from './CompetenceCreate';
import CompetenceEdit from './CompetenceEdit';
import CompetenceShow from './CompetenceShow';

const RedirectToHomepage = () => <Navigate to="/" replace />;

export default {
  config: {
    list: RedirectToHomepage,
    show: CompetenceShow,
    create: CompetenceCreate,
    edit: CompetenceEdit,
    icon: SettingsIcon,
    options: {
      label: 'Compétences'
    }
  },
  dataModel: {
    types: ['heco:Competence'],
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Compétences',
      fields: {
        'pair:description': 'Description',
        'heco:hasDiscipline': 'Discipline(s)',
        'heco:hasArea': 'Secteur(s)',
        'heco:hasStudiedObject': "Objet(s) d'étude",
        'heco:hasTool': 'Outil(s)',
        'heco:isCompetenceOf': 'Détenue par',
        'dc:creator': 'Créé par',
        'heco:isAcquiredByJob': 'Acquis à travers le(s) poste(s)',
        'heco:isAcquiredByCommitment': 'Acquis à travers la (les) contribution(s)',
        'heco:isAcquiredByTraining': 'Acquis à travers la (les) formation(s)',
      }
    },
    en: {
      name: 'Competences',
      fields: {
        'pair:description': 'Description',
        'heco:hasDiscipline': 'Has discipline(s)',
        'heco:hasArea': 'Has area(s)',
        'heco:hasStudiedObject': "Has studied objet(s)",
        'heco:hasTool': 'Has Tool(s)',
        'heco:isCompetenceOf': 'Is competence of',
        'dc:creator': 'Creator',
        'heco:isAcquiredByJob': 'Is acquired by job(s)',
        'heco:isAcquiredByCommitment': 'Is acquired by commitment(s)',
        'heco:isAcquiredByTraining': 'Is acquired by training',
      }
    }
  }
};
