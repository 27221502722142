import React from 'react';
import SideList from '../../common/list/SideList/SideList';
import { QuickAppendReferenceArrayField } from '@semapps/field-components';
import ChipList from '../../common/list/ChipList';
import DateToDateField from '../../common/field/DateToDateField';
import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

const JobSide = (props) => {
  const translate=useTranslate();

  return(
  <>
    <SideList>
      <Typography variant="h7" color="primary">{translate('app.dates')}</Typography>
      <DateToDateField startDate="heco:startDate" endDate="heco:endDate" variant="subtitle1" color="primary" />
      <Typography variant="h7" color="primary">{translate('app.menu.organizations')}</Typography>
      <QuickAppendReferenceArrayField reference="Organization" source="heco:heldAt">
        <ChipList primaryText="pair:label" linkType="" externalLinks />
      </QuickAppendReferenceArrayField>
      <Typography variant="h7" color="primary">{translate('app.menu.commitments')}</Typography>
      <QuickAppendReferenceArrayField reference="Occupation" source="heco:hasOccupation">
        <ChipList primaryText="pair:label" linkType="" externalLinks />
      </QuickAppendReferenceArrayField>
      <Typography variant="h7" color="primary">{translate('app.menu.themes')}</Typography>
      <QuickAppendReferenceArrayField reference="Theme" source="heco:hasTopic">
        <ChipList primaryText="pair:label" linkType="" externalLinks />
      </QuickAppendReferenceArrayField>
    </SideList>
  </>
  );
}

export default JobSide;
