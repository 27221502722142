import React from 'react';
import SideList from '../../common/list/SideList/SideList';
import { QuickAppendReferenceArrayField } from '@semapps/field-components';
import ChipList from '../../common/list/ChipList';
import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

const CompetenceSide = (props) => {
  const translate=useTranslate();

  return (
    <>
      <SideList>
        <Typography variant="h7" color="primary">{translate('app.menu.jobs')}</Typography>
        <QuickAppendReferenceArrayField 
          reference="Job" 
          source="heco:isAcquiredByJob"
        >
          <ChipList primaryText="pair:label" linkType="show" externalLinks />
        </QuickAppendReferenceArrayField>

        <Typography variant="h7" color="primary">{translate('app.menu.commitments')}</Typography>
        <QuickAppendReferenceArrayField 
          reference="Commitment" 
          source="heco:isAcquiredByCommitment"
        >
          <ChipList primaryText="pair:label" linkType="show" externalLinks />
        </QuickAppendReferenceArrayField>

        <Typography variant="h7" color="primary">{translate('app.menu.trainings')}</Typography>
        <QuickAppendReferenceArrayField 
          reference="Training" 
          source="heco:isAcquiredByTraining"
        >
          <ChipList primaryText="pair:label" linkType="show" externalLinks />
        </QuickAppendReferenceArrayField>
      </SideList>
    </>
  )
};

export default CompetenceSide;
