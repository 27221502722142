import React from 'react';
// import { ReferenceArrayInput, MultiServerAutocompleteArrayInput } from "@semapps/input-components";
// import { ReferenceArrayInput } from "@semapps/input-components";
import { ReferenceArrayInput } from "react-admin";
import MultiServerAutocompleteArrayInput from "./MultiServerAutocompleteArrayInput";
import { LexiconCreateDialog, fetchESCO, fetchWikidata } from "@semapps/interop-components"

// const ifTwoLetters = ({ q }) => !!(q && q.length > 1);
const filterAllServers = { _servers: '@all' };
const perPage = 1000;

export const OrganizationsInput = ({ source }) => (
  <ReferenceArrayInput 
    reference="Organization" 
    source={source} 
    filter={filterAllServers} 
    perPage={perPage}
    >
    <MultiServerAutocompleteArrayInput 
      optionText="pair:label"
      create={
          <LexiconCreateDialog
            fetchLexicon={fetchWikidata()}
            selectData={data => ({
              'pair:label': data.label,
              'rdfs:comment': data.summary,
              'http://www.w3.org/ns/prov#wasDerivedFrom': data.uri,
            })}
          />
          }
      fullWidth 
    />
  </ReferenceArrayInput>
);

export const OccupationsInput = ({ source }) => (
  <ReferenceArrayInput 
    reference="Occupation" 
    source={source} 
    filter={filterAllServers}
    perPage={perPage}
  >
    <MultiServerAutocompleteArrayInput 
      optionText="pair:label" 
      create={
        <LexiconCreateDialog
          fetchLexicon={fetchESCO('https://esco.commondata.one', 'occupation')}
          selectData={data => ({
            'pair:label': data.label,
            'http://www.w3.org/ns/prov#wasDerivedFrom': data.uri,
          })}
          />
        }
      fullWidth />
  </ReferenceArrayInput>
);

export const DisciplinesInput = ({ source }) => (
  <ReferenceArrayInput 
    reference="Discipline" 
    source={source} 
    filter={filterAllServers}
    perPage={perPage}
  >
    <MultiServerAutocompleteArrayInput 
      optionText="pair:label" 
      create={
        <LexiconCreateDialog
          fetchLexicon={fetchWikidata()}
          selectData={data => ({
            'pair:label': data.label,
            'rdfs:comment': data.summary,
            'http://www.w3.org/ns/prov#wasDerivedFrom': data.uri,
          })}
        />
        }
      fullWidth 
    />
  </ReferenceArrayInput>
);

export const AreasInput = ({ source }) => (
  <ReferenceArrayInput 
    reference="Area" 
    source={source} 
    filter={filterAllServers}
    perPage={perPage}
  >
    <MultiServerAutocompleteArrayInput 
    optionText="pair:label" 
    create={
      <LexiconCreateDialog
        fetchLexicon={fetchWikidata()}
        selectData={data => ({
          'pair:label': data.label,
          'rdfs:comment': data.summary,
          'http://www.w3.org/ns/prov#wasDerivedFrom': data.uri,
        })}
      />
      }
    fullWidth />
  </ReferenceArrayInput>
);

export const StudiedObjectsInput = ({ source }) => (
  <ReferenceArrayInput 
    reference="StudiedObject" 
    source={source} 
    filter={filterAllServers}
    perPage={perPage}
  >
    <MultiServerAutocompleteArrayInput 
      optionText="pair:label" 
      create={
        <LexiconCreateDialog
          fetchLexicon={fetchWikidata()}
          selectData={data => ({
            'pair:label': data.label,
            'rdfs:comment': data.summary,
            'http://www.w3.org/ns/prov#wasDerivedFrom': data.uri,
          })}
        />
        }    
      fullWidth />
  </ReferenceArrayInput>
);

export const ToolsInput = ({ source }) => (
  <ReferenceArrayInput 
    reference="Tool" 
    source={source} 
    filter={filterAllServers}
    perPage={perPage}
  >
    <MultiServerAutocompleteArrayInput 
      optionText="pair:label" 
      create={
        <LexiconCreateDialog
          fetchLexicon={fetchWikidata()}
          selectData={data => ({
            'pair:label': data.label,
            'rdfs:comment': data.summary,
            'http://www.w3.org/ns/prov#wasDerivedFrom': data.uri,
          })}
        />
        }
      fullWidth />
  </ReferenceArrayInput>
);

export const JobsInput = ({ source, identity }) => (
  <ReferenceArrayInput
    reference="Job" 
    source={source} 
    filter={ {_servers: '@all', "heco:isJobOf": identity?.id } }
    perPage={perPage}
  >
    <MultiServerAutocompleteArrayInput optionText="pair:label" fullWidth />
  </ReferenceArrayInput>
);

export const TrainingsInput = ({ source, identity }) => (
  <ReferenceArrayInput 
    reference="Training" 
    source={source} 
    filter={ {_servers: '@all', "heco:isTrainingOf": identity?.id } }
    perPage={perPage}
  >
    <MultiServerAutocompleteArrayInput optionText="pair:label" fullWidth />
  </ReferenceArrayInput>
);

export const CommitmentsInput = ({ source, identity }) => (
  <ReferenceArrayInput 
    reference="Commitment" 
    source={source} 
    filter={ {_servers: '@all', "heco:isCommitmentOf": identity?.id } }
    perPage={perPage}
  >
    <MultiServerAutocompleteArrayInput optionText="pair:label" fullWidth />
  </ReferenceArrayInput>
);

export const CompetencesInput = ({ source, identity }) => {
  return (
  <ReferenceArrayInput 
    reference="Competence" 
    source={source} 
    filter={ {_servers: '@all', "heco:isCompetenceOf": identity?.id } }
    perPage={perPage}
  >
    <MultiServerAutocompleteArrayInput optionText="pair:label" fullWidth />
  </ReferenceArrayInput>
)};

export const ThemesInput = (props) => (
  <ReferenceArrayInput 
    reference="Theme" 
    filter={filterAllServers} 
    perPage={perPage}
    {...props}
  >
    <MultiServerAutocompleteArrayInput
      optionText="pair:label"
      create={
        <LexiconCreateDialog
          fetchLexicon={fetchWikidata()}
          selectData={data => ({
            'pair:label': data.label,
            'rdfs:comment': data.summary,
            'http://www.w3.org/ns/prov#wasDerivedFrom': data.uri,
          })}
        />
      }
      fullWidth
    />
  </ReferenceArrayInput>
);

export const ProjectsInput = ({ source }) => (
  <ReferenceArrayInput 
    reference="Project" 
    source={source} 
    filter={filterAllServers}
    perPage={perPage}
  >
    <MultiServerAutocompleteArrayInput
      optionText="pair:label"
      create={
        <LexiconCreateDialog
          fetchLexicon={fetchWikidata()}
          selectData={data => ({
            'pair:label': data.label,
            'rdfs:comment': data.summary,
            'http://www.w3.org/ns/prov#wasDerivedFrom': data.uri,
          })}
        />
      }
      fullWidth
    />
  </ReferenceArrayInput>
);

export { default as DateTimeInput } from './DateTimeInput';
