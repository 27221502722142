import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { MarkdownInput } from '@semapps/markdown-components';
import { OrganizationsInput, 
  ThemesInput,
  CompetencesInput,
  DateTimeInput } from '../../common/input';
import { useGetIdentity } from 'react-admin';

const TrainingForm = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  if (identityLoading) return <>Loading...</>;

  return (
    <SimpleForm redirect="show">
      <TextInput source="pair:label" fullWidth />
      <TextInput source="rdfs:comment" fullWidth />
      <OrganizationsInput source="heco:providedBy" />
      <CompetencesInput source="heco:acquiredCompetence" identity={identity} />
      <MarkdownInput multiline source="pair:description" fullWidth />
      <DateTimeInput source="heco:startDate" />
      <DateTimeInput source="heco:endDate" />
      <ThemesInput source="heco:hasTopic" />
    </SimpleForm>
  );
};

export default TrainingForm;
