import React from 'react';
import Edit from '../../layout/Edit';
import ComptetenceForm from './CompetenceForm';
import CompetenceTitle from './CompetenceTitle';

const CompetenceEdit = props => {
  return (
    <Edit redirect="show" title={<CompetenceTitle />} {...props}>
        <ComptetenceForm />
    </Edit>
)};

export default CompetenceEdit;
