import React from 'react';
import { SimpleForm, useTranslate } from 'react-admin';
import { MarkdownInput } from '@semapps/markdown-components';
import { 
  DisciplinesInput, 
  AreasInput, 
  StudiedObjectsInput, 
  ToolsInput, 
  JobsInput, 
  CommitmentsInput, 
  TrainingsInput
} from '../../common/input';
import { Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useGetIdentity } from 'react-admin';

const ComptetenceForm = ({ className, ...rest }) => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const translate = useTranslate();
  if (identityLoading) return <>Loading...</>;

    return (
    <SimpleForm {...rest}>
      <Grid container spacing={5} fullWidth>
        <Grid item xs={6}>
          <MarkdownInput multiline source="pair:description"/>
          <JobsInput source="heco:isAcquiredByJob" identity={identity} />
          <CommitmentsInput source="heco:isAcquiredByCommitment" identity={identity} />
          <TrainingsInput source="heco:isAcquiredByTraining" identity={identity} />
        </Grid>
        <Grid item xs={6}>
          <Alert severity="info" fullWidth>
            {translate('app.discipline_definition')}
            <a href={translate('app.qualities_link_definition')} target="_blank">({translate('app.see_more')})</a>
          </Alert>
          <DisciplinesInput source="heco:hasDiscipline"/>
          <Alert severity="info" fullWidth>
            {translate('app.area_definition')}
            <a href={translate('app.qualities_link_definition')} target="_blank">({translate('app.see_more')})</a>
          </Alert>
          <AreasInput source="heco:hasArea" />
          <Alert severity="info" fullWidth>
          {translate('app.studied_object_definition')}
            <a href={translate('app.qualities_link_definition')} target="_blank">({translate('app.see_more')})</a>
          </Alert>
          <StudiedObjectsInput source="heco:hasStudiedObject" />
          <Alert severity="info" fullWidth>
          {translate('app.tool_definition')}
            <a href={translate('app.qualities_link_definition')} target="_blank">({translate('app.see_more')})</a>
          </Alert>
          <ToolsInput source="heco:hasTool" />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default ComptetenceForm;
