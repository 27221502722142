import * as React from 'react';
import { useListContext, useResourceContext, useCreatePath } from 'react-admin';
import { Link } from 'react-router-dom';
import { Card, CardContent, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    height: '50vh',
  },
  details: {
    display: 'flex',
    marginTop: 8,
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  image: {
    width: 180,
    minWidth: 180,
    minHeight: 145,
    backgroundColor: theme.palette.grey['300'],
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  content: {
    // flex: '1 0 auto',
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
}));

const CardsList = ({ CardComponent, link, ...rest }) => {
  const classes = useStyles();
  const { data, isLoading } = useListContext();
  const createPath = useCreatePath();
  const resource = useResourceContext();

  // console.log("data=",data);
  if (isLoading) return <LinearProgress />;
  if (data.length === 0) return <p>No data</p>;

  return (
    <div style={{ margin: '1em' }}>
    {
      data.map(record => {
        return (
          <Link key={record.id} to={createPath( { resource:resource, id:record.id, type:link } )} className={classes.root}>
            <Card key={record.id} className={classes.details}>
              <CardContent className={classes.content}>
                <CardComponent record={record} {...rest} />
              </CardContent>
            </Card>
          </Link>
        )
      })
    }
    </div>
  )
}

CardsList.defaultProps = {
  link: 'show',
};

export default CardsList;
