import React from 'react';
import { useRecordContext } from 'react-admin';

const OptionRenderer = ({ optionText, dataServers, keyword, ...rest }) => {
    const record = useRecordContext();    
    // console.log("OptionRenderer =", record);
    const server = dataServers && Object.values(dataServers).find(server => record.id.startsWith(server.baseUrl));
    let recordToDisplay = "";

    if (optionText === "id")
    {
        recordToDisplay = record?.id.substring(record?.id.lastIndexOf('/') + 1)
    }
    else
    {
        recordToDisplay = record[optionText]
    }

    if (rest.create && (!record || !server)) return "Créer "+keyword ;

    return (
    <span>
        {recordToDisplay}
        {server && (
        <em className="serverName" style={{ color: 'grey' }}>
            &nbsp;({server.name})
        </em>
        )}
    </span> 
    );
};

export default OptionRenderer;