import React from 'react';
import TrainingTitle from './TrainingTitle';
import Edit from '../../layout/Edit';
import TrainingForm from './TrainingForm';

const TrainingEdit = props => {
  return (
  <Edit title={<TrainingTitle />} redirect="show" {...props}>
    <TrainingForm />
  </Edit>
)};

export default TrainingEdit;
