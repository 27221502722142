import React from 'react';
import { MarkdownField } from '@semapps/markdown-components';
import { useCheckAuthenticated } from '@semapps/auth-provider';
import TrainingTitle from './TrainingTitle';
import Show from '../../layout/Show';
import CardLayout from "../../layout/CardLayout";
import { Typography } from '@mui/material';
import TrainingSide from "./TrainingSide";
import { useTranslate } from 'react-admin';

const TrainingShow = props => {
  useCheckAuthenticated();
  const translate=useTranslate();
  return (
    <Show title={<TrainingTitle />}  {...props}>
      <CardLayout side={<TrainingSide/>}>
        <Typography variant="h5" color="primary">{translate('app.description_short')}</Typography>
        <MarkdownField source="rdfs:comment" />
        <Typography variant="h5" color="primary">{translate('app.description_long')}</Typography>
        <MarkdownField source="pair:description" />
      </CardLayout>
    </Show>
  );
}

export default TrainingShow;
