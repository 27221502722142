import React from 'react';
import { LinearProgress, useGetIdentity, useGetList } from 'react-admin';
import Create from '../../layout/create/Create';
import CompetenceForm from './CompetenceForm';

const CompetenceCreate = props => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { data: listOfCompetence, isLoading: competenceLoading} = useGetList('Competence');
  
  if (identityLoading || competenceLoading) return <LinearProgress />;

  let idUser = ""
  let idComp = 0

  idUser = identity.id.substring(identity.id.lastIndexOf('/') + 1)
  const filteredlistOfCompetence = listOfCompetence.filter(item => item["dc:creator"] && item["dc:creator"].includes(identity.id))
  idComp = filteredlistOfCompetence.length+1;

  const transform = (dataTransform) => {
    return ({
    ...dataTransform,
    'pair:label': idUser+"_comp_"+idComp,
    'heco:isCompetenceOf': identity.id
  })}

  return (
      <Create redirect="show" {...props} transform={transform}>
        <CompetenceForm />
      </Create>
    );
}

export default CompetenceCreate;
