import React from 'react';
import isSameDay from 'date-fns/isSameDay';
import { DateField, useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

const DateToDateField = ({ source, startDate, endDate, variant, color }) => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (record[startDate])
  {
    if (isSameDay(new Date(record[startDate]), new Date(record[endDate]))) {
      return <DateField source={startDate} options={{ year: 'numeric', month: 'long', day: 'numeric' }} variant={variant} color={color} />;
    } else {
      return (
        <Typography component="span" variant={variant} color={color}>
          Du&nbsp;
          <DateField source={startDate} options={{ year: 'numeric', month: 'short', day: 'numeric' }} variant={variant} color={color} />
          &nbsp;au&nbsp;
          <DateField source={endDate} options={{ year: 'numeric', month: 'short', day: 'numeric' }} variant={variant} color={color} />
        </Typography>
      );
    }
  } else {
      return (
        <Typography component="span" variant={variant} color={color}>
          ({translate('app.no_date')})
        </Typography>
      );

  }
};

export default DateToDateField;
