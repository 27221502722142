import React from 'react';
import Create from '../../layout/create/Create';
import CommitmentForm from './CommitmentForm';
import { useGetIdentity } from 'react-admin';

const CommitmentCreate = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  if (identityLoading) return <>Loading...</>;

  const transform = (dataTransform) => {
    return ({
    ...dataTransform,
    'heco:isTrainingOf': identity.id
  })}

  return (
  <Create redirect="show" transform={transform}>
    <CommitmentForm />
  </Create>
  )
}

export default CommitmentCreate;
