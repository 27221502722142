import React from 'react';
import { Layout } from 'react-admin';
import { Box, Typography } from '@mui/material';
import MyAppBar from './MyAppBar';
import MyMenu from './MyMenu';
import MySidebar from './MySidebar';

const MyLayout = ({children, ...props}) => {
  return (
    <Layout
      appBar={MyAppBar}
      menu={MyMenu}
      sidebar={MySidebar}
      {...props}
    >
        <Box sx={{ width: '75%', margin:'auto' }}>
          {children}
        </Box>
        <Box sx={{ width: '75%', margin:'auto' }}>
          <Typography variant="subtitle2" color="textSecondary" align="right">
            <a href="https://bo-a.carto4ch.huma-num.fr/" target="_blank" rel="noopener noreferrer">Back-Office Carto4CH</a>
            &nbsp;|&nbsp;
            <a href="https://semapps.org/" target="_blank" rel="noopener noreferrer">SemApps.org</a>
            &nbsp;|&nbsp;
            <a href="https://portal.carto4ch.huma-num.fr/" target="_blank" rel="noopener noreferrer">Portail Carto4CH</a>
          </Typography>
        </Box>
    </Layout>
  );
};

export default MyLayout;