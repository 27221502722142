import React from 'react';
import { TextField, useTranslate } from 'react-admin';
import { MarkdownField } from '@semapps/markdown-components';
import { useCheckAuthenticated } from '@semapps/auth-provider';
import JobTitle from './JobTitle';
import Show from '../../layout/Show';
import CardLayout from "../../layout/CardLayout";
import { ReferenceArrayField, SeparatedListField } from '@semapps/field-components';
import { Typography } from '@mui/material';
import JobSide from "./JobSide";

const JobShow = props => {
  useCheckAuthenticated();
  const translate=useTranslate();
  return (
    <Show title={<JobTitle />}  {...props}>
      <CardLayout side={<JobSide/>}>
        <Typography variant="h5" color="primary">{translate('app.description_short')}</Typography>
        <MarkdownField source="rdfs:comment" />
        <Typography variant="h5" color="primary">{translate('app.menu.types')}</Typography>
        <ReferenceArrayField source="pair:hasType" reference="Type">
          <SeparatedListField link={false}>
            <TextField source="pair:label" />
          </SeparatedListField>
        </ReferenceArrayField>
        <Typography variant="h5" color="primary">{translate('app.description_long')}</Typography>
        <MarkdownField source="pair:description" />
      </CardLayout>
    </Show>
  );
}

export default JobShow;
