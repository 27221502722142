import React from 'react';
import SideList from '../../common/list/SideList/SideList';
import { QuickAppendReferenceArrayField } from '@semapps/field-components';
import ChipList from '../../common/list/ChipList';
import DateToDateField from '../../common/field/DateToDateField';
import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

const TrainingSide = (props) => {
  const translate=useTranslate();
 
  return (
  <>
    <SideList>
      <Typography variant="h7" color="primary">{translate('app.dates')}</Typography>
      <DateToDateField startDate="heco:startDate" endDate="heco:endDate" variant="subtitle1" color="primary" />

      <Typography variant="h7" color="primary">{translate('app.menu.organizations')}</Typography>
      <QuickAppendReferenceArrayField reference="Organization" source="heco:providedBy">
        <ChipList primaryText="pair:label" linkType="" externalLinks />
      </QuickAppendReferenceArrayField>

      <Typography variant="h7" color="primary">{translate('app.menu.competences')}</Typography>
      <QuickAppendReferenceArrayField reference="Competence" source="heco:acquiredCompetence">
        <ChipList primaryText="pair:label" linkType="" externalLinks />
      </QuickAppendReferenceArrayField>

      <Typography variant="h7" color="primary">{translate('app.menu.themes')}</Typography>
      <QuickAppendReferenceArrayField reference="Theme" source="heco:hasTopic">
        <ChipList primaryText="pair:label" linkType="" externalLinks />
      </QuickAppendReferenceArrayField>
    </SideList>
  </>
)};

export default TrainingSide;
