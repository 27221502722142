import React from 'react';
import { useShowContext, useResourceContext } from 'react-admin';
import RightLabel from './RightLabel';
import { LinearProgress } from '@mui/material';

const SideList = ({ children }) => {
  const { isLoading, record } = useShowContext();
  const resource = useResourceContext();

  if (isLoading) return <LinearProgress />;

  return React.Children.map(children, field => {
    return (
    field && React.isValidElement(field) ? (
      <div key={field.props.source}>
        {field.props.addLabel ? (
          <RightLabel
            record={record}
            label={field.props.label}
            source={field.props.source}
            disabled={false}
          >
            {field}
          </RightLabel>
        ) : typeof field.type === 'string' ? (
          field
        ) : (
          React.cloneElement(field, {
            record,
            resource
          })
        )}
      </div>
    ) : null
  )});
};

export default SideList;
