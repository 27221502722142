import React from 'react';
import { useCheckAuthenticated } from '@semapps/auth-provider';
import CardsList from "../../common/list/CardsList";
import List from "../../layout/List";
import CompetenceCard from "./CompetenceCard";

const CompetenceList = props => {
  useCheckAuthenticated();
  
  return (
    <List {...props}>
      <CardsList
        CardComponent={CompetenceCard}
        link="show"
      />
    </List>
  );
}

export default CompetenceList;
