import React from 'react';
import Create from '../../layout/create/Create';
import JobForm from './JobForm';
import { useGetIdentity } from 'react-admin';

const JobCreate = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  if (identityLoading) return <>Loading...</>;

  const transform = (dataTransform) => {
    return ({
    ...dataTransform,
    'heco:isJobOf': identity.id
  })}

  return (
    <Create redirect="show" transform={transform}>
      <JobForm />
    </Create>
  )
}

export default JobCreate;
