import React from "react";
// import { Redirect } from 'react-router';
import { Navigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import TrainingCreate from './TrainingCreate';
import TrainingEdit from './TrainingEdit';
import TrainingShow from './TrainingShow';

const RedirectToHomepage = () => <Navigate to="/" replace />;

export default {
  config: {
    list: RedirectToHomepage,
    show: TrainingShow,
    create: TrainingCreate,
    edit: TrainingEdit,
    icon: SettingsIcon,
    options: {
      label: 'Formations'
    }
  },
  dataModel: {
    types: ['heco:Training'],
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Formation |||| Formations',
      fields: {
        'pair:label': 'Titre de la formation',
        'rdfs:comment': 'Courte description',
        'pair:description': 'Description',
        'heco:startDate': 'Date de début',
        'heco:endDate': 'Date de fin',
        'heco:providedBy': 'Formé par',
        'heco:hasTopic': 'A pour thème',
        'heco:acquiredCompetence': 'Compétence(s) acquise(s)'
      }
    },
    en: {
      name: 'Training |||| Trainings',
      fields: {
        'pair:label': 'Title',
        'rdfs:comment': 'Short description',
        'pair:description': 'Description',
        'heco:startDate': 'Start date',
        'heco:endDate': 'End date',
        'heco:providedBy': 'Provided by',
        'heco:hasTopic': 'Has topic(s)',
        'heco:acquiredCompetence': 'Acquired competence(s)'
      }
    }
  }
};
