import React from "react";
import { Navigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import JobCreate from './JobCreate';
import JobEdit from './JobEdit';
import JobShow from './JobShow';

const RedirectToHomepage = () => <Navigate to="/" replace />;

export default {
  config: {
    list: RedirectToHomepage,
    show: JobShow,
    create: JobCreate,
    edit: JobEdit,
    icon: SettingsIcon,
    options: {
      label: 'Postes'
    }
  },
  dataModel: {
    types: ['heco:Job'],
    fieldsMapping: {
      title: 'pair:label'
    }
  },
  translations: {
    fr: {
      name: 'Poste |||| Postes',
      fields: {
        'pair:label': 'Titre du poste',
        'rdfs:comment': 'Courte description',
        'pair:description': 'Description',
        'heco:startDate': 'Date de début',
        'heco:endDate': 'Date de fin',
        'heco:heldAt': 'Travaille pour',
        'heco:hasOccupation': 'A pour profession',
        'heco:hasTopic': 'A pour thèmes',
        'pair:hasType': 'A pour type',
        'heco:acquiredCompetence': 'Compétence(s) acquise(s)'
      }
    },
    en: {
      name: 'Job |||| jobs',
      fields: {
        'pair:label': 'Title',
        'rdfs:comment': 'Short description',
        'pair:description': 'Description',
        'heco:startDate': 'Start date',
        'heco:endDate': 'End date',
        'heco:heldAt': 'Held at',
        'heco:hasOccupation': 'Has occupation(s)',
        'heco:hasTopic': 'Has topic(s)',
        'pair:hasType': 'Has type',
        'heco:acquiredCompetence': 'Acquired competence(s)'
      }
    }
  }
};
