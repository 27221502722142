import React from 'react';
import CommitmentTitle from './CommitmentTitle';
import Edit from '../../layout/Edit';
import CommitmentForm from './CommitmentForm';

const CommitmentEdit = props => {
  return (
  <Edit redirect="show" title={<CommitmentTitle />} {...props}>
    <CommitmentForm />
  </Edit>
)};

export default CommitmentEdit;
