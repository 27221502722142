import React, { useState, useCallback } from 'react';
import { AutocompleteArrayInput } from 'react-admin';
import { useDataServers } from '@semapps/semantic-data-provider';
import OptionRenderer from './OptionRenderer';

const MultiServerAutocompleteArrayInput = ({ optionText, ...rest }) => {
  // optionText = "pair:label" ou "id"
  // console.log("MultiServerAutocompleteArrayInput =", { optionText, ...rest });
  const dataServers = useDataServers();
  const [keyword, setKeyword] = useState("");
  const matchSuggestion = useCallback(
  (filterValue, choice) => {
      // filterValue = lettres entrées manuellement par l'utilisateur qui filtre la liste
      // choice = liste affichée dans les suggestions de l'input
      setKeyword(filterValue)
      return ( choice[optionText].toLowerCase().match(filterValue.toLowerCase()) )
    }, [optionText]
  );
  return (
    <AutocompleteArrayInput
      matchSuggestion={matchSuggestion}
      optionText={<OptionRenderer 
        optionText={optionText} 
        dataServers={dataServers} 
        keyword={keyword}
        {...rest} />
        }
      inputText={choice => {
        let choiceToDisplay = "";
        if (optionText === "id")
        {
          choiceToDisplay = choice?.id.substring(choice?.id.lastIndexOf('/') + 1)
        }
        else
        {
          choiceToDisplay = choice[optionText]
        }
    
        return choiceToDisplay}
      }
    {...rest}
    />
  );
};

export default MultiServerAutocompleteArrayInput;