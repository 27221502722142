import React from 'react';
import { useCheckAuthenticated } from '@semapps/auth-provider';
import CardsList from "../../common/list/CardsList";
import List from "../../layout/List";
import TrainingCard from "./TrainingCard";

const TrainingList = props => {
  useCheckAuthenticated();
  
  return (
    <List {...props}>
      <CardsList
        CardComponent={TrainingCard}
        link="show"
      />
    </List>
  );
}

export default TrainingList;
