import React, { useMemo } from 'react';
import { useGetIdentity, 
  ResourceContextProvider, 
  ListContextProvider, 
  useListController, 
  usePermissions } from 'react-admin';
import { useCreateContainer } from '@semapps/semantic-data-provider';
import ListView from "./ListView";

const List = (props) => {
  // console.log("props= ", props );
  const { data: identity, isLoading: identityLoading } = useGetIdentity();

  // Paramètre très important, car par défaut, perPage=10 !
  const perPage = 1000;
  const controllerProps = useListController({perPage, ...props});
  const createContainerUri = useCreateContainer(props.resource);
  const { permissions } = usePermissions(createContainerUri);

  // console.log("permissions= ", permissions );

  // https://data-msh.carto4ch.huma-num.fr/competences/competence-1
  // https://data-msh.carto4ch.huma-num.fr/users/yannickduthe

  // Format des données reçues dans controllerProps.data
  // const data = {
  // "http://toto-id" : {id : "http://toto-id"},
  // "http://titi-id" : {id : "http://titi-id"},
  // "http://tata-id" : {id : "http://tata-id"} 
  // }

  const inArray = ( item => {
      // Put each record["heco:is..."] in array
      return item && (Array.isArray(item)) ? item : [item];
  })

  controllerProps.data = useMemo(() => {
    // controllerProps n'est valorisé que dans le useMemo()
    // console.log("controllerProps.data = ", controllerProps.data );

    if (!controllerProps.data) return null
    // let filteredData = [];
    return controllerProps.data
      // On applique le filtre sur l'utilisateur
      .filter(record => {
        const checkIdentity = ( 
          inArray(record["heco:isJobOf"]).includes(identity?.id)
        || inArray(record["heco:isCommitmentOf"]).includes(identity?.id)
        || inArray(record["heco:isTrainingOf"]).includes(identity?.id)
        || inArray(record["heco:isCompetenceOf"]).includes(identity?.id)
        )
        // if (record["type"] === "heco:Job")
        //   console.log("filter = ", [record, checkIdentity, identity?.id]);
        return record && checkIdentity
      })
  },[controllerProps.data, identity]);

  return(
    <ResourceContextProvider value={props.resource}>
      <ListContextProvider value={controllerProps}>
        <ListView
          {...controllerProps}
          {...props}
          hasCreate={props.hasCreate && !!permissions && permissions.some(p => ['acl:Append', 'acl:Write', 'acl:Control'].includes(p['acl:mode']))}
        />
      </ListContextProvider>
    </ResourceContextProvider>
  )
};

export default List;
