import React from 'react';
import { TextField } from 'react-admin';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 20,
    lineHeight: 1.2,
    marginBottom: 8
  },
  description: {
    marginTop: 10,
    display: 'block',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
    maxHeight: '4.2em',
  },
  chip: {
    marginLeft: 10,
    marginTop: -5
  }
}));

const CompetenceCard = ({ record }) => {
  const classes = useStyles();
  return (
    <>
      <Typography component="div">
        {record["pair:label"]}
      </Typography>
      <TextField record={record} source="pair:description" variant="body2" className={classes.description}/>
    </>
  );
};

export default CompetenceCard;
