import * as React from 'react';
import { Logout, UserMenu, useUserMenu } from 'react-admin';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HelpIcon from '@mui/icons-material/Help';

const HelpMenu = React.forwardRef((props, ref) => {
    // We are not using MenuItemLink so we retrieve the onClose function from the UserContext
    const { onClose } = useUserMenu();

    return (
        <MenuItem
            ref={ref}
            // It's important to pass the props to allow Material UI to manage the keyboard navigation
            {...props}
            sx={{ color: 'text.secondary' }}
            onClick={event => {

                onClose(); // Close the menu
            }}
        >
            <ListItemIcon>
                <HelpIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
                <Link to="https://portal.carto4ch.huma-num.fr/docs/project-documentation/doc-demonstrator/my-competences" target="_blank" rel="noopener noreferrer">
                    Help
                </Link>
            </ListItemText>
        </MenuItem>
    );
});

const MyUserMenu = () => (
    <UserMenu>
        <HelpMenu />
        <Logout />
    </UserMenu>
);

export default MyUserMenu;