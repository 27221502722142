import React from 'react';
import { useShowContext } from 'react-admin';
import { Box, Card, CardContent, Grid, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  side: {
    padding: 10,
    backgroundColor: '#e0e0e0'
  }
});

const CardLayout = ({ children, side }) => {
  const classes = useStyles();
  const { isLoading, record } = useShowContext();

  if (isLoading) return <LinearProgress />;

  return(
    <Card className={classes.root}>
      <Grid container>
        <Grid item xs={8}>
          <CardContent className={classes.content}>
            {React.Children.map(children, child => (
                React.cloneElement(child, { record })
              ))}
          </CardContent>
        </Grid>
        <Grid item xs={4}>
          {side &&
            <Box className={classes.side}>
                { React.Children.map(side, child => (
                  React.cloneElement(child, { record })
                ))}
            </Box>
          }
        </Grid>
      </Grid>
    </Card>
  )
};

export default CardLayout;
