import React from 'react';
import CompetenceList from "./resources/Competence/CompetenceList";
import TrainingList from "./resources/Training/TrainingList";
import JobList from "./resources/Job/JobList";
import CommitmentList from "./resources/Job/JobList";
import Alert from '@mui/material/Alert';
import { LinearProgress, useGetIdentity } from 'react-admin';
import { Typography } from '@mui/material';

const HomePage = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  if (identityLoading) return <LinearProgress />;

  return (
  <>
  <Typography variant="h4" color="primary" align="center">
    {identity?.webIdData["foaf:givenName"]+" "+identity?.webIdData["foaf:familyName"]}
  </Typography>
  <JobList
    defaultTitle="app.menu.jobs"
    resource="Job"
    hasCreate={true}
  />
  <CommitmentList
    defaultTitle="app.menu.commitments"
    resource="Commitment"
    hasCreate={true}
  />
  <TrainingList
    defaultTitle="app.menu.trainings"
    resource="Training"
    hasCreate={true}
  />
  <CompetenceList
    defaultTitle="app.menu.competences"
    resource="Competence"
    hasCreate={true}
  />
  <Alert severity="info">
    Pour toute difficulté, n'hésitez pas à consulter le <a href="https://portal.carto4ch.huma-num.fr/docs/project-documentation/doc-demonstrator/my-competences" target="_blank">manuel utilisateur</a>
  </Alert>
  </>
)};

export default HomePage;
