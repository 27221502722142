import React from 'react';
import { TextField, useTranslate } from 'react-admin';
import { useCheckAuthenticated } from '@semapps/auth-provider';
import CompetenceTitle from './CompetenceTitle';
import Show from '../../layout/Show';
import CardLayout from "../../layout/CardLayout";
import { ReferenceArrayField } from '@semapps/field-components';
import ChipList from '../../common/list/ChipList';
import { Typography } from '@mui/material';
import CompetenceSide from "./CompetenceSide";

const CompetenceShow = props => {
  const translate=useTranslate();
  useCheckAuthenticated();
  return (
    <Show title={<CompetenceTitle />}  {...props}>
      <CardLayout side={<CompetenceSide />}>
        <Typography variant="h5" color="primary">{translate('app.description_short')}</Typography>
          <TextField source="pair:description" variant="body2"/>
        <Typography variant="h5" color="primary">{translate('app.menu.disciplines')}</Typography>
        <ReferenceArrayField reference="Competence" source="heco:hasDiscipline">
          <ChipList primaryText="pair:label" linkType=""/>
        </ReferenceArrayField>
        <Typography variant="h5" color="primary">{translate('app.menu.areas')}</Typography>
        <ReferenceArrayField reference="Competence" source="heco:hasArea">
          <ChipList primaryText="pair:label" linkType=""/>
        </ReferenceArrayField>
        <Typography variant="h5" color="primary">{translate('app.menu.studiedobjects')}</Typography>
        <ReferenceArrayField reference="Competence" source="heco:hasStudiedObject">
          <ChipList primaryText="pair:label" linkType=""/>
        </ReferenceArrayField>
        <Typography variant="h5" color="primary">{translate('app.menu.tools')}</Typography>
        <ReferenceArrayField reference="Competence" source="heco:hasTool">
          <ChipList primaryText="pair:label" linkType=""/>
        </ReferenceArrayField>
      </CardLayout>
    </Show>
  );
}

export default CompetenceShow;
