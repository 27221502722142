import React from 'react';
import { MarkdownField } from '@semapps/markdown-components';
import { useCheckAuthenticated } from '@semapps/auth-provider';
import CommitmentTitle from './CommitmentTitle';
import Show from '../../layout/Show';
import CardLayout from "../../layout/CardLayout";
import { Typography } from '@mui/material';
import CommitmentSide from "./CommitmentSide";
import { useTranslate } from 'react-admin';

const CommitmentShow = props => {
  useCheckAuthenticated();
  const translate = useTranslate();
  return (
    <Show title={<CommitmentTitle />}  {...props}>
      <CardLayout side={<CommitmentSide/>}>
        <Typography variant="h6" color="primary">{translate('app.description_short')}</Typography>
        <MarkdownField source="rdfs:comment" />
        <Typography variant="h6" color="primary">{translate('app.description_long')}</Typography>
        <MarkdownField source="pair:description" />
      </CardLayout>
    </Show>
  );
}

export default CommitmentShow;
