import React from 'react';
import { useCheckAuthenticated } from '@semapps/auth-provider';
import CardsList from "../../common/list/CardsList";
import List from "../../layout/List";
import JobCard from "./JobCard";

const JobList = props => {
  useCheckAuthenticated();

  return (
    <List {...props}>
      <CardsList
        CardComponent={JobCard}
        link="show"
      />
    </List>
  );
}

export default JobList;
