import React from 'react';
import { CreateButton, useListContext, useTranslate } from 'react-admin';
import { Box, Typography, Grid } from '@mui/material';

const ListView = (props) => {
  // console.log("props.children",props.children);
  const { defaultTitle, hasCreate } = useListContext(props);
  console.log("ListView defaultTitle=", defaultTitle);
  const translate = useTranslate();
  return(
    <>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="h4" color="primary" component="h1">
            {translate(defaultTitle)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" alignItems="middle" justifyContent="right" pt={3}>
            {hasCreate && <CreateButton />}
          </Box>
        </Grid>
      </Grid>
      <Box mt={1}>
        {props.children}
      </Box>
    </>
  )
};

export default ListView;
